@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz@10..48&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Preahvihear&display=swap');


body {
  margin: 0;
  /* font-family: "Raleway", serif !important; */
  /* font-family: 'Bricolage Grotesque', sans-serif!important; */
  font-family: 'Preahvihear', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to left, rgb(27 20 41), rgb(20 15 35));
}
